export enum ModalTypes {
  DiscussionDelete = 'discussion.delete',
  DiscussionDeleteFail = 'discussion.delete.fail',
  DiscussionDeleteLoading = 'discussion.delete.loading',
  ShareWishlist = 'shareWishlist',
  ShippingAddress = 'shippingAddress',
  EditAddWishlistProduct = 'editAddWishlistProduct',
  BuyOnOther = 'buyOnOther',
  MobileDrawer = 'mobileDrawer',
  InviteForm = 'inviteForm',
  CreateFolder = 'createFolder',
  UploadFiles = 'uploadFiles',
}
