import { makeAutoObservable } from 'mobx';

import { ModalTypes } from '@/domains/Modals/ModalTypes';

class ModalStore {
  isOpen = true;

  currentModal: ModalTypes = null;

  modalTypes = ModalTypes;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setModalType(modalType: ModalTypes) {
    if (modalType !== this.currentModal) {
      this.currentModal = modalType;
    }
  }

  showModal(modalType?: ModalTypes) {
    if (modalType) {
      this.setModalType(modalType);
    }

    if (!this.currentModal) return;

    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
  }

  toggleModal() {
    this.isOpen = !this.isOpen;
  }
}

export default new ModalStore();
